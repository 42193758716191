import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const Box = styled.div`
  @media (max-width: 768px) {
    position: fixed;
    top: 80px;
    left: ${props => (props.open ? "-100%" : "0")};
    display: flex;
    justify-content: flex-end;
    height: 100%;
    padding: 32px;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    transition: all 0.3s ease-in;
    background: ${theme.main.colors.white};
  }

  @media ${device.laptop} {
    display: flex;
    justify-content: flex-end;
    width: 80%;
  }
`
export default Box
