import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const Container = styled.div`
  position: relative;
  display: ${props => (props.flex ? "flex" : "normal")};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "row"};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${props => (props.alignItems ? props.alignItems : "flex-start")};
  padding: ${props =>
    props.padding ? props.padding : `0 ${theme.main.sizes.paddings.default}`};
  background: ${props =>
    props.background ? `url(${props.background})` : "none"};
  margin: 0 auto;

  text-align: ${props => (props.textAlign ? props.textAlign : "left")};

  transition: 0.3s;

  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : "cover"};

  background-position: ${props =>
    props.backgroundPosition ? props.backgroundPosition : "normal"};

  background-repeat: no-repeat;

  height: ${props => (props.height ? props.height : "auto")};
  /* For Tablets, starting at Portrait */

  @media ${device.tablet} {
    padding: 0 40px;
  }

  @media ${device.tablet} {
    background-position: ${props =>
      props.backgroundPositionSm ? props.backgroundPositionSm : "center"};
  }

  @media ${device.laptop} {
    padding: 0 40px;
    max-width: 960px;
    margin: 0 auto;

    height: ${props => (props.heightMd ? props.heightMd : "auto")};

    background-position-y: 40%;
  }

  @media ${device.laptopL} {
    padding: 0 40px;
    max-width: 1024px;
    margin: 0 auto;
  }
`

export default Container
