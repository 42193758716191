/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./normalize.css"
import GlobalFonts from "../Fonts"

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { createGlobalStyle } from "styled-components"
import theme from "../../themes"
import Navbar from "../Navbar"
import navitems from "../../data/navigation"
import Footer from "../Footer"
import { useGlobal, setGlobal } from "reactn"

setGlobal({
  overflow: false,
  boxSizing: "border-box",
})

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "SourceSansPro", Arial, Helvetica, sans-serif;
  }

  html, body {
    overflow: ${props => (props.overflow ? "hidden" : "visible")};
   // -webkit-overflow-scrolling: ${props =>
     props.overflow ? "touch" : "auto"};

    // position: ${props => (props.overflow ? "fixed" : "relative")};
  }

  p {
    font-size: ${theme.main.sizes.typography.mobile.p};
    line-height: 32px;
    margin: 0 0 40px 0;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalFonts />
      <GlobalStyle overflow={useGlobal("overflow")[0]} theme={theme} />
      <Navbar
        title="hello"
        data={navitems}
        theme={theme}
        siteTitle={data.site.siteMetadata.title}
      >
        yeah
      </Navbar>

      <main style={{ paddingTop: "80px" }}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
