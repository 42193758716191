// NavbarLinks.js

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const NavItem = styled(Link)`
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  border-radius: 8px;
  color: #ffffff;
  white-space: nowrap;
  margin-bottom: 16px;
  width: 100%;
  transition: all 200ms ease-in;
  position: relative;
  background: red;
  font-weight: normal;
  box-sizing: border-box;
  height: 64px;
  font-size: 22px;
  line-height: 22px;
  padding: 20px 0;
  font-size: ${theme.main.sizes.typography.mobile.p};
  z-index: 6;
  cursor: pointer;

  &:nth-child(1) {
    background: ${theme.main.colors.pinkDark};
  }

  &:nth-child(2) {
    background: ${theme.main.colors.greenLight};
  }

  &:nth-child(3) {
    background: ${theme.main.colors.gray};
  }

  &:nth-child(4) {
    background: ${theme.main.colors.brand.primary};
  }

  &:nth-child(5) {
    background: ${theme.main.colors.pinkLight};
  }

  &:nth-child(6) {
    background: #7fbec2;
    // todo create variable
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    top: 6%;
    left: 2%;
    bottom: 5%;
    width: 95%;
    height: 80%;
    transition: all 0.4s ease-in;
    border: 2px dashed white;
    border-radius: 8px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    font-weight: normal;
    width: 16.66%;
    margin: 0;
    height: 32px;
    padding: 4px 0 0 0;

    &:before {
      top: 8%;
      left: 2%;
      bottom: 5%;
      width: 95%;
      height: 80%;
      border-radius: 0px;
      border: none;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`

const Links = () => {
  return (
    <>
      <NavItem to="/betreuungsangebote">Angebote</NavItem>
      <NavItem to="/paedagogik">Pädagogik</NavItem>
      <NavItem to="/tagesablauf">Tagesablauf</NavItem>
      <NavItem to="/oeffnungszeiten">Öffnungszeiten</NavItem>
      <NavItem to="/ueber-uns">Über Uns</NavItem>
      <NavItem to="/kontakt">Kontakt</NavItem>
    </>
  )
}

export default Links
