import logo from "../../images/logo.svg"
import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const ImageBlock = styled.img`
  width: 100%;
  margin: ${props => (props.margin ? props.margin : "0")};
  width: ${props => (props.width ? props.width : "100%")};

  ${props => (props.position ? props.position : "static")};

  @media ${device.tablet} {
    width: ${props => (props.widthSm ? props.widthSm : "100%")};
    margin: ${props => (props.marginSm ? props.marginSm : "0")};

    ${props => (props.positionSm ? props.positionSm : "static")};
  }

  @media ${device.laptop} {
    width: ${props => (props.widthMd ? props.widthMd : "100%")};
    margin: ${props => (props.marginMd ? props.marginMd : "0")};
    ${props => (props.positionMd ? props.positionMd : "static")};
  }

  @media ${device.laptopL} {
    width: ${props => (props.widthLg ? props.widthLg : "50%")};
    margin: ${props => (props.marginLg ? props.marginLg : "0")};
    ${props => (props.positionLg ? props.positionLg : "static")};
  }
`

export default ImageBlock
