import sizes from "./sizes"
import colors from "./colors"

const theme = {
  main: {
    colors,
    sizes,
  },
}

export default theme
