import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const H2 = styled.h2`
  font-size: ${theme.main.sizes.typography.mobile.h2};
  text-align: ${props => (props.textAlign ? props.textAlign : "left")};
  margin: 0 0 8px 0;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "8px")};
  display: block;
  width: 100%;

  @media ${device.laptop} {
    font-size: ${theme.main.sizes.typography.desktop.h2};
    // line-height: 62px;
    margin: 0 0 16px 0;
    margin-bottom: ${props =>
      props.marginBottomMd ? props.marginBottomMd : "8px"};
  }
`

export default H2
