import styled from "styled-components"
import Img from "gatsby-image"
import React from "react"
// test
const StyledImg = styled(Img)`
  // margin: 0px auto 32px auto;
  display: block;
`

const Logo = ({ fixed }) => {
  return <StyledImg fixed={fixed} />
}

export default Logo
