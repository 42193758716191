import { v4 as uuid } from "uuid"

const data = [
  {
    id: uuid(),
    name: "Startseite",
    link: "/",
    isActive: true,
  },
  {
    id: uuid(),
    name: "Services",
    link: "/",
    isActive: true,
  },
  {
    id: uuid(),
    name: "Contact",
    link: "/",
    isActive: true,
  },
]

export default data
