import React, { useState } from "react"
import styled from "styled-components"
import theme from "../../themes"
import Logo from "./Logo"
import Toggle from "./Toggle"
import Hamburger from "./Hamburger"
import Box from "./Box"
import Links from "./Links"
import Slogan from "./Slogan"
import Container from "../Container"
import { Link, StaticQuery } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { device } from "../../themes/device"
import ImageBlock from "../ImageBlock"
import logoText from "../../images/logo_text.svg"
const Inner = styled(Container)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-items: center;
`

const Navbar = styled.div`
  width: 100%;
  max-width: 100%;
  position: fixed;
  display: flex;
  color: ${theme.main.colors.black};
  justify-content: space-between;
  box-sizing: border-box;
  height: ${theme.main.sizes.navbar.mobile};
  background: ${theme.main.colors.white};
  // padding: ${theme.main.sizes.paddings.default};
  border-bottom: 4px solid ${theme.main.colors.pink};
  z-index: 20;

  top: ${props => (props.showHidden ? "0" : "-80px")};
  transition: 0.4s;

  @media ${device.laptop} {
    width: 100%;
  }

`

const Main = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [showHidden] = useState(true)

  return (
    <Navbar showHidden={showHidden}>
      <StaticQuery
        query={graphql`
          query {
            logo: file(relativePath: { eq: "logo_fever.png" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(height: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            logoText: file(relativePath: { eq: "logo_text.png" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(height: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={data => {
          console.log(data)
          return (
            <Inner>
              <Link to="/">
                <Logo fixed={data.logo.childImageSharp.fixed} />
              </Link>
              <Link to="/">
                <ImageBlock
                  src={logoText}
                  width={"200px"}
                  widthSm={"200px"}
                  widthMd={"160px"}
                  marginMd={"0 16px"}
                  marginLg={"0 16px"}
                />
              </Link>

              <Toggle
                navbarOpen={navbarOpen}
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                {navbarOpen ? <Hamburger open /> : <Hamburger />}
              </Toggle>
              {navbarOpen ? (
                <Box>
                  <Links color={"white"} />
                </Box>
              ) : (
                <Box open>
                  <Links color={"black"} />
                </Box>
              )}
            </Inner>
          )
        }}
      />
    </Navbar>
  )
}

export default Main
