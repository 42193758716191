import styled from "styled-components"
import logo_text from "../../images/logo_text.svg"
const Slogan = styled.img.attrs({
  src: logo_text,
})`
  margin: 0;
  padding: 0;
  width: 160px;
`
export default Slogan
