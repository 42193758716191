import { createGlobalStyle } from "styled-components"

import SourceSansProBoldWoff from "./SourceSansPro-Bold.woff"
import SourceSansProBoldWoff2 from "./SourceSansPro-Bold.woff2"
import SourceSansProRegularWoff from "./SourceSansPro-Regular.woff"
import SourceSansProRegularWoff2 from "./SourceSansPro-Regular.woff2"
import SourceSansProSemiBoldWoff from "./SourceSansPro-SemiBold.woff"
import SourceSansProSemiBoldWoff2 from "./SourceSansPro-SemiBold.woff2"
import SourceSansProLightWoff from "./SourceSansPro-Light.woff"
import SourceSansProLightWoff2 from "./SourceSansPro-Light.woff2"

export default createGlobalStyle`

@font-face {
    font-family: "SourceSansPro";
    src: url(${SourceSansProBoldWoff2}) format("woff2"),
      url(${SourceSansProBoldWoff}) format("woff");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "SourceSansPro";
    src: url(${SourceSansProRegularWoff2}) format("woff2"),
      url(${SourceSansProRegularWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "SourceSansPro";
    src: url(${SourceSansProSemiBoldWoff2}) format("woff2"),
      url(${SourceSansProSemiBoldWoff}) format("woff");
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "SourceSansPro";
    src: url(${SourceSansProLightWoff2}) format("woff2"),
      url(${SourceSansProLightWoff}) format("woff");
    font-weight: 300;
    font-style: normal;
  }
  `
