import React from "react"
import styled from "styled-components"
import Container from "../Container"
import { Link, StaticQuery } from "gatsby"
import Address from "./Address"
import FooterLink from "./Link"
import Slogan from "./Slogan"
import theme from "../../themes"
import logonewfriends from "../../images/logo_newfriends.png"
import logocodeflow from "../../images/codeflow_logo.svg"
import dog_insta from "../../images/dog_insta.svg"
import dog_facebook from "../../images/dog_facebook.svg"
import Img from "gatsby-image"
import { Grid } from "@material-ui/core"
import H2 from "../H2"
import SocialMedia from "./SocialMedia"

const StyledFooter = styled.footer`
  color: black;
  background: white;
  margin-bottom: 0;
  padding: 0 0 64px 0;
  box-sizing: border-box;
  border-top: 3px solid ${theme.main.colors.pinkLight};
`

const Footer = () => {
  return (
    <StyledFooter>
      <StaticQuery
        query={graphql`
          query {
            logo: file(relativePath: { eq: "logo_fever.png" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(height: 192) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            logoNewFriends: file(relativePath: { eq: "logo_newfriends.png" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 240) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Container>
              <Img
                style={{ margin: "-2px auto 40px auto", display: "block" }}
                fixed={data.logo.childImageSharp.fixed}
              />
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <H2>Anfahrt</H2>
                  <Address>
                    THE BUSINESS LOFT
                    <br />
                    Kita Traumfänger
                    <br />
                    Eisfeldstrasse 2a
                    <br />
                    6003 Luzern
                    <br />
                    Schweiz
                    <br />
                    <br />
                    <FooterLink href="mailto:info@kita-traumfaenger.ch">
                      info@kita-traumfaenger.ch
                    </FooterLink>
                    <FooterLink
                      style={{ color: "white" }}
                      href="tel:+41791758223"
                    >
                      +41 79 175 82 23
                    </FooterLink>
                  </Address>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <H2>Social Media</H2>
                  <SocialMedia>
                    <a
                      href="https://www.instagram.com/kita_traumfaenger"
                      target="_blank"
                    >
                      <img
                        src={dog_insta}
                        style={{
                          width: "120px",
                          height: "120px",
                        }}
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/kita_traumfaenger"
                      target="_blank"
                    >
                      <img
                        src={dog_facebook}
                        style={{
                          width: "120px",
                          height: "120px",
                          display: "none",
                        }}
                      />
                    </a>
                  </SocialMedia>
                </Grid>
                <Grid item xs={12} md={12}>
                  <H2>Partner</H2>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href="http://www.new-friends.ch" target="_blank">
                    <Img
                      style={{
                        display: "block",
                        width: "120px",
                      }}
                      fluid={data.logoNewFriends.childImageSharp.fluid}
                    />
                  </a>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      />
    </StyledFooter>
  )
}

export default Footer
