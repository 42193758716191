const colors = {
  black: "#000000",
  white: "#ffffff",
  gray: "#888981",
  green: "#327378",
  greenDark: "#327378",
  greenLight: "#8AA379",
  pink: "#FB8796",
  pinkDark: "#C14C67",
  pinkLight: "#FCA8B6",
  yellow: "#FFA039",
  content: {
    background: "#fafafb",
    "background-alpha": "rgba(14, 22, 34, 0.02)",
  },
  leftMenu: {
    "link-hover": "#1c2431",
    "link-color": "#919bae",
    "title-color": "#5b626f",
  },
  brand: {
    primary: "#327378",
    secondary: "#FB8796",
  },
}

export default colors
