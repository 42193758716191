const sizes = {
  navbar: {
    mobile: "80px",
    tablet: "80px",
    desktop: "80px",
  },
  paddings: {
    small: "8px",
    smaler: "14px",
    default: "16px",
    big: "64px",
    bigger: "18px",
  },
  container: {
    mobile: "auto",
    tablet: "768px",
    desktop: "1024px",
  },
  margin: {
    small: "8px",
    smaller: "14px",
    default: "16px",
    big: "24px",
    bigger: "18px",
  },
  typography: {
    mobile: {
      h1: "32px",
      h2: "26px",
      h3: "16px",
      p: "22px",
    },
    tablet: {
      h1: "32px",
      h2: "24px",
      h3: "16px",
      p: "22px",
    },
    desktop: {
      h1: "40px",
      h2: "32px",
      h3: "16px",
      p: "24px",
    },
  },
}

export default sizes
